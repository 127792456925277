import React, { Component } from 'react';
import './UserVideo.css'

export default class OpenViduVideoComponent extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidUpdate(props) {
        if (props && !!this.videoRef) {
            this.props.streamManager.addVideoElement(this.videoRef.current);
        }
    }

    componentDidMount() {
        if (this.props && !!this.videoRef) {
            this.props.streamManager.addVideoElement(this.videoRef.current);
        }
    }
 
    render() {
        return (
        <div className='video'>
            <video
              style={{width:"400px", height:"200px", margin:"30px"}}
              autoPlay={true}
              ref={this.videoRef}
              
            />
        </div>
    )
}
}