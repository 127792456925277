
import { OpenVidu } from 'openvidu-browser';
import './App.css';
import axios from 'axios';
import UserVideoComponent from './UserVideoComponent';
import { useEffect, useState } from 'react';

const APPLICATION_SERVER_URL = 'https://srv-appopnvi.vatilab.com/'//'http://192.168.88.31:9000/' //'https://srv-appopnvi.vatilab.com/';

function App() {

  const [mySessionId, setMySessionId] = useState('SessionA');
  const [myUserName, setMyUserName] = useState('Participant' + Math.floor(Math.random() * 100));
            
  const [session, setSession] = useState(undefined); // charger avec objet generer par OpenVidu
  const [mainStreamManager, setMainStreamManager] = useState(undefined);  // Main video of the page. Will be the 'publisher' or one of the 'subscribers'
  const [publisher, setPublisher] = useState(undefined); // notre propre flux webCam
  const [subscribers, setSubscribers] = useState([]); // list flux actif
  const [OV, setOV] = useState(undefined);
  const [currentVideoDevice, setCurrentVideoDevice] = useState(undefined);
  const [live, setLive] = useState(false);
  const [showLive, setShowLive] = useState(false);
  const [cam, setCam] = useState(true);
  const [mic, setMic] = useState(true);
  const [streamVideo, setStreamVideo] = useState(null);
  const [chatData, setChatData] = useState([]);
  const [message, setMessage] = useState("");
  const [theSession, setTheSession] = useState(undefined);
  const [stateBtn, setStateBtn] = useState(false);
  const [mySession, setMySession] = useState(false);

  const getToken = async ()=> {
    const sessionId = await createSession(mySessionId);
    return await createToken(sessionId);
  }

   const createSession = async(sessionId)=> {
    const response = await axios.post(APPLICATION_SERVER_URL + 'api/sessions', { customSessionId: sessionId }, {
        headers: { 'Content-Type': 'application/json', },
    });
    return response.data; // The sessionId
  }
  
   const createToken = async (sessionId) => {
    const response = await axios.post(APPLICATION_SERVER_URL + 'api/sessions/' + sessionId + '/connections', {}, {
        headers: { 'Content-Type': 'application/json', },
    });
    console.log("Token Users : ", response.data);
    return response.data; // The token
  }

  useEffect(() => {
    window.addEventListener('beforeunload', leaveSession);
    
    return () => {
        window.removeEventListener('beforeunload', leaveSession);
    };
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
    
  const joinSessionForSeeLive = (e) => {
        e.preventDefault();
        setShowLive(true);
        
        const OV = new OpenVidu();
        const theSession = OV.initSession(); 
        
        
        theSession.on('streamCreated', (event) => {
            
            const subscriber = theSession.subscribe(event.stream, undefined);
            setSubscribers((prevSubscribers) => [...prevSubscribers, subscriber]);
          //  setSubscribers([subscriber])
         setStreamVideo(subscriber);
        });
        
        // On every Stream destroyed...
        theSession.on('streamDestroyed', (event) => {
            deleteSubscriber(event.stream.streamManager);
        });

        theSession.on('exception', (exception) => {
            console.warn(exception);
        });

        theSession.on('signal:my', (event) => {
           
            setChatData(prev => [...prev, { name:event.from, message: event.data }]);
            console.log("MESSAGE : ", event.data); // Message
            console.log("EVENT FORM : ", event.from); // Connection object of the sender
            console.log("EVENT TYPE : ", event.type); // The type of message ("my-chat")
        });

        setTheSession(theSession);

        getToken().then((token) => {
             theSession.connect(token, { clientData: myUserName })
                .then(async () => {
             
                //   let publisher = await OV.initPublisherAsync(undefined, {
                //        audioSource: undefined, // The source of audio. If undefined default microphone
                //        videoSource: undefined, // The source of video. If undefined default webcam
                //        publishAudio: false, // Whether you want to start publishing with your audio unmuted or not
                //        publishVideo: false, // Whether you want to start publishing with your video enabled or not
                //       resolution: '200x200', // The resolution of your video
                //        frameRate: 30, // The frame rate of your video
                //        insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                //    });
      
                    // --- 6) Publish your stream ---
                    // IMP *************************
                //   theSession.publish(publisher);
    
                //    const devices = await OV.getDevices();
                    
                //   setMainStreamManager(publisher);
                //    setPublisher(publisher); 
                })
                .catch((error) => {
                    console.log('There was an error connecting to the session:', error.code, error.message);
                });
        });
    } 

    useEffect(()=> {
        if(theSession){
           
            if(stateBtn){
        getToken().then((token) => {
            
            theSession.connect(token, { clientData: myUserName })
            .then(async () => {
             
                theSession.signal({
                    data: message,  // Any string (optional)
                    to: [],                     // Array of Connection objects (optional. Broadcast to everyone if empty)
                    type: 'my-'             // The type of message (optional)
                  })
                  .then(() => {
                       
                      console.log(' FINNALY SENT THE MESSAGE VIEWER -> BROADCASTER : ', message);
                  })
                  .catch(error => {
                      console.error(error);
                  });

                 })
                .catch((error) => {
                    console.log('There was an error connecting to the session:', error.code, error.message);
                });
            })
            setStateBtn(false);
        }
        }
        },[stateBtn]);

  const handleChangeSessionId =(e)=> {
      setMySessionId(e.target.value)
  }

  const handleChangeUserName = (e) => {
      setMyUserName(e.target.value);
  }

  const handleMainVideoStream =(stream)=> {
    if(mainStreamManager !== stream) {
      setMainStreamManager(stream);
    }
  }

  const deleteSubscriber = (streamManager) => {
    let subscriberCopy = subscribers; 
    let index = subscriberCopy.indexOf(streamManager, 0);
    if(index > -1){
      subscriberCopy.splice(index, 1);
      setSubscribers(subscriberCopy)
    }
  }

  const joinSession = (e) => {
    e.preventDefault();
      const OV = new OpenVidu();
      setOV(OV);
    
    setSession(OV.initSession());
    
    var mySession = OV.initSession();
                 
    if(mySession){

        // CHARGER TABLEAU PUBLISHERS 
    /*   mySession.on('streamCreated', (event) => {
        const subscriber = mySession.subscribe(event.stream, undefined);
    //    const subscribersCopy = subscribers;
    //    subscribersCopy.push(subscriber);

        // Update the state with the new subscribers
    //    setSubscribers(subscribersCopy)
 
    setSubscribers((prevSubscribers) => [...prevSubscribers, subscriber]);
    });
*/
    mySession.on('signal:my-', (event) => {
    
        setChatData(prev => [...prev, { name:event.from, message: event.data }]);

        console.log("MESSAGE : ", event.data); // Message
        console.log("EVENT FORM : ", event.from); // Connection object of the sender
        console.log("EVENT TYPE : ", event.type); // The type of message ("my-chat")
    });


    // On every Stream destroyed...
    mySession.on('streamDestroyed', (event) => {
        // Remove the stream from 'subscribers' array
        deleteSubscriber(event.stream.streamManager);
    });
 
    // On every asynchronous exception...
    mySession.on('exception', (exception) => {
        console.warn(exception);
    });

    setMySession(mySession);

    getToken().then((token) => {
        
         mySession.connect(token, { clientData: myUserName })
            .then(async () => {
               let publisher = await OV.initPublisherAsync(undefined, {
                    audioSource: undefined, // The source of audio. If undefined default microphone
                    videoSource: undefined, // The source of video. If undefined default webcam
                    publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                    publishVideo: true, // Whether you want to start publishing with your video enabled or not
                    resolution: '640x480', // The resolution of your video
                    frameRate: 30, // The frame rate of your video
                    insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                    mirror: false, 
                });

            mySession.publish(publisher);
       
            const devices = await OV.getDevices();
        
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            //    const currentVideoDeviceId = publisher.stream.getMediaStream().getVideoTracks()[0].getSettings().deviceId;
            //    const currentVideoDevice = videoDevices.find(device => device.deviceId === currentVideoDeviceId);

                // Set the main video in the page to display our webcam and store our Publisher
            //  setCurrentVideoDevice(currentVideoDevice);
            const currentVideoDeviceId = publisher.stream.getMediaStream().getVideoTracks()[0].getSettings().deviceId;
            const currentVideoDevice = videoDevices.find(device => device.deviceId === currentVideoDeviceId);
    
            setCurrentVideoDevice(currentVideoDevice);
            setMainStreamManager(publisher);
                setPublisher(publisher); 
            })
            .catch((error) => {
                console.log('There was an error connecting to the session:', error.code, error.message);
            });
    });
  }
  }

  useEffect(()=> {
    if(mySession){
        if(stateBtn){
    getToken().then((token) => {
        
        mySession.connect(token, { clientData: myUserName })
        .then(async () => {
            mySession.signal({
                data: message,  // Any string (optional)
                to: [],                     // Array of Connection objects (optional. Broadcast to everyone if empty)
                type: 'my'             // The type of message (optional)
              })
              .then(() => {
                
                  console.log('Message successfully sent ', message);
              })
              .catch(error => {
                  console.error(error);
              });
                })
            .catch((error) => {
                console.log('There was an error connecting to the session:', error.code, error.message);
            });
        })
        setStateBtn(false);
    }
    }
    },[stateBtn]);

 

  const leaveSession =() => {
    // --- 7) Leave the session by calling 'disconnect' method over the Session object ---
    const mySession = session;

    if (mySession) {
        mySession.disconnect();
    }

    // Empty all properties...
    setOV(null);

    setSession(undefined);
    setSubscribers([]);
    setMySessionId('SessionA');
    setMyUserName('Participant' + Math.floor(Math.random() * 100));
    setMainStreamManager(undefined);
    setPublisher(undefined);
}

 const switchCamera = async () => {
  try {
    
      const devices = await OV.getDevices();
    
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      if (videoDevices && videoDevices.length > 1) {

          const newVideoDevice = videoDevices.filter(device => device.deviceId !== currentVideoDevice.deviceId)
  
          if (newVideoDevice.length > 0) {
              // Creating a new publisher with specific videoSource
              // In mobile devices the default and first camera is the front one
              var newPublisher = OV.initPublisher(undefined, {
                  videoSource: newVideoDevice[0].deviceId,
                  publishAudio: true,
                  publishVideo: true,
                  mirror: true
              });

              //newPublisher.once("accessAllowed", () => {
              await session.unpublish(mainStreamManager)

              await session.publish(newPublisher)
              setCurrentVideoDevice(newVideoDevice[0]);
              setMainStreamManager(newPublisher);
              setPublisher(newPublisher);
          }
      }
  } catch (e) {
      console.error("THE ERROR : ",e);
  }
}

const handleVideo = () => {
    setCam(!cam);
    publisher.publishVideo(!cam);
}

const handleAudio = () => {
    setMic(prev => !prev);
    publisher.publishAudio(!mic);
}
 
  return (
    <div className="App">

    <button onClick={ ()=>setLive(true) }>Demarer Direct</button>
    
    <form className="form-group" onSubmit={ joinSessionForSeeLive }>
                                
                                    <label>Participant: </label>
                                    
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="userName"
                                        value={myUserName}
                                        onChange={ handleChangeUserName }
                                        required
                                    />
                                    <br />
                                    <label> Session: </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="sessionId"
                                        value={mySessionId}
                                        onChange={ handleChangeSessionId }
                                        required
                                    />
                                    <br/>
                                    <button>Regarder Direct</button>
                            </form>
    
      { session === undefined && live === true && showLive === false &&(
                    <div>
                            <h1> Join a video session </h1>
                            <form className="form-group" onSubmit={ joinSession }>
                                
                                    <label>Participant: </label>
                                    <br />
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="userName"
                                        value={myUserName}
                                        onChange={ handleChangeUserName }
                                        required
                                    />
                                    <br />
                                    <label> Session: </label>
                                    <br />
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="sessionId"
                                        value={mySessionId}
                                        onChange={ handleChangeSessionId }
                                        required
                                    />
                                    <br/>
                                    <button>JOIN</button>
                            </form>
                    </div>
        )}

                { session !== undefined && live === true && showLive === false && (
                    <div id="session">
                        <div id="session-header">
                            <h1 id="session-title">{mySessionId}</h1>
                            <div style={{display:"flex", justifyContent:"space-between", width:"400px", margin:"0 auto"}}>
                                <input
                                    type="button"
                                    id="buttonLeaveSession"
                                    onClick={ leaveSession }
                                    value="Leave session"
                                /> 
                                {/* <input
                                    type="button"
                                    id="buttonSwitchCamera"
                                    onClick={ switchCamera }
                                    value="Switch Camera"
                                /> */}
                                    
                                <input
                                    type="button"
                                    onClick={ handleVideo }
                                    value="Camera"
                                />
                                
                                <input
                                    type="button"
                                    onClick={ handleAudio }
                                    value="Micro"
                                />
                            </div>
                        </div>
                 
                        {mainStreamManager !== undefined && (
                            <div id="main-video" className="col-md-6">
                                <h1 style={{color:"blue"}}>Bienvenue {myUserName}</h1>
                                {/* <h2 style={{color: "green"}} >Nombre visionneurs : {subscribers.length} </h2> */}
                                <UserVideoComponent streamManager={mainStreamManager} />
                            </div>
                        )}
                  
                  
                        <label>Message : </label>
                          <input type="text" value={message} onChange={ (e)=>setMessage(e.target.value) }/>
                          <input
                                type="button"
                                onClick={ ()=> setStateBtn(true) }
                                value="Camera"
                            />
                        
                        <div id="video-container" className="col-md-6" style={{border:"solid 1px green"}}>
                            
                            { 
                            /*publisher !== undefined && (
                                <div className="stream-container col-md-6 col-xs-6" onClick={() => handleMainVideoStream(publisher)}>
                                    <UserVideoComponent
                                        streamManager={ publisher } />
                                </div>
                            ) 
                            
                                // ALL SUBSCRIBERS
                            subscribers.length ? ( subscribers.map((sub, i) => (
                                <div key={sub.id} className="stream-container col-md-6 col-xs-6" onClick={() => handleMainVideoStream(sub)}>
                                    <span>{sub.id}</span>
                                    <UserVideoComponent streamManager={sub} />
                                </div>
                              )) ) : (<h1>Pas de donnee a afficher</h1>)
                            
                            */}
                            
                        </div>

                    </div>
                ) } 

                    {/* AFFICHE SEE LIVE */}
                {
                    showLive === true && ( 
                      subscribers.length ? (
                        <>
{/*                         
                        <div style={{border:"solid 1px green"}}>
                        {
                        subscribers.map((sub, i) => (
                            <div key={sub.id} className="stream-container col-md-6 col-xs-6" onClick={() => handleMainVideoStream(sub)}>
                                <span style={{color:"purple"}}>{sub.id}</span>
                                <UserVideoComponent streamManager={sub} />
                                
                                <input
                                className="btn btn-large btn-danger"
                                type="button"
                                id="buttonLeaveSession"
                                onClick={ leaveSession }
                                value="Leave session"
                                />

                            </div>
                          ))
                        } 
                        </div> */}
                         <>
                          <span style={{color:"purple"}}>{streamVideo.id}</span>
                          <UserVideoComponent streamManager={subscribers[0]} />
                          
                          <input
                          className="btn btn-large btn-danger"
                          type="button"
                          id="buttonLeaveSession"
                          onClick={ leaveSession }
                          value="Leave session"/>

                          <label>Message</label>
                          <input type="text" value={message} onChange={ (e)=>setMessage(e.target.value) }/>
                          <button onClick={ ()=> setStateBtn(true) }>Envoyer</button>
                         </>
                          </>
                          
                    ) 
                    :
                    (
                      <h1> 
                        Pas de Direct ( subscribers )
                      </h1>)
                    )  
                }

                {
                    <ul>
                        {
                        chatData.length ? (
                            chatData.map((obj, index)=> {       
                                return(
                                    <li key={index}>
                                        <h3>{`${obj.name} || ${obj.message}`}</h3>
                                    </li>
                                )
                            })
                        ) : (<h2>Pas de message a afficher</h2>)
                        }
                    </ul>
                }

    </div>
  );

}

export default App;